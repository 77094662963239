import { useMutation, useQuery } from "@tanstack/react-query";
import {
  App,
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Flex,
  Form,
  Input,
  Row,
  Table,
  notification
} from "antd";
import locale from "antd/es/date-picker/locale/pt_BR";
import axios from "axios";
import "dayjs/locale/pt-br";
import React, { useState } from "react";
import MyModal from "../../components/MyModal";
import { plan } from "../../helpers/plans";
import Title from "antd/es/typography/Title";
import { NavLink } from "react-router-dom";

// import { Container } from './styles';
const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 }
};
const tailLayout = {
  wrapperCol: { offset: 0, span: 24 }
};

function Batch() {
  const { notification } = App.useApp();
  const [form] = Form.useForm();
  const [idPlan, setIdPlan] = useState(null);
  const [planData, setPlanData] = useState(null);
  const [isModalInvestorVisible, setIsModalInvestorVisible] = useState(false);
  const [isModalManagerVisible, setIsModalManagerVisible] = useState(false);
  const [entryDate, setEntryDate] = useState(null);

  const handleClickBachEntryInvestor = (e) => {
    setPlanData(e);
    setIdPlan(e.id);
    setIsModalInvestorVisible(true);
  };

  const handleClickBachEntryManager = (e) => {
    setPlanData(e);
    setIdPlan(e.id);
    setIsModalManagerVisible(true);
  };

  const handleCancelInvestor = () => {
    setIsModalInvestorVisible(false);
    form.resetFields();
  };
  const handleCancelManager = () => {
    setIsModalManagerVisible(false);
    form.resetFields();
  };

  //verificar se ja foi lançado a rentabilidade do mes de base de calculo.
  const { data: dataSelectPlan } = useQuery(["plans"], () =>
    axios
      .get(`${process.env.REACT_APP_API}/v1/plan`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      })
      .then((res) => {
        return res.data.data;
      })
  );

  const put = async (dataForm) => {
    const response = await axios.post(
      // `${process.env.REACT_APP_API}/v1/transaction/batch-yield-posting`,
      `${process.env.REACT_APP_API}/v1/transaction/bath-transaction`,
      dataForm,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      }
    );

    return response.data;
  };
  const putManager = async (dataForm) => {
    const response = await axios.put(
      `${process.env.REACT_APP_API}/v1/gains-manager/create`,
      dataForm,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      }
    );

    return response.data;
  };
  const { mutate, isLoading: sending } = useMutation(put, {
    onSuccess: (data) => {
      notification.success({
        message: "Sucesso",
        description: "Lançamento de lote realizado com sucesso!"
      });
      setIsModalInvestorVisible(false);
      form.resetFields();
    }
  });
  const { mutate: mutateManager, isLoading: sendingManager } = useMutation(
    putManager,
    {
      onSuccess: (data) => {
        notification.success({
          message: "Sucesso",
          description: "Lançamento de lote realizado com sucesso!"
        });
        setIsModalManagerVisible(false);
        form.resetFields();
      }
    }
  );

  const onFinish = (values) => {
    mutate(values);
  };
  const onFinishManager = (values) => {
    mutateManager(values);
  };

  const onChangeDatePicker = (date, dateString) => {
    setEntryDate(dateString);
  };

  const gridStyle = {
    width: "25%",
    textAlign: "center"
  };

  return (
    <div className="layout-content">
      <Flex justify="space-between" align="center">
        <Title level={4}>Lançamento em lote</Title>
      </Flex>
      <Divider />

      <Row>
        <Col span={24}>
          <Table
            columns={[
              {
                title: "Plano",
                dataIndex: "plan",
                key: "plan",
                render: (record) => (
                  <>
                    <Button
                      onClick={() => handleClickBachEntryInvestor(record)}
                      type="link"
                    >
                      {record.plan}
                    </Button>
                  </>
                )
              }
            ]}
            dataSource={dataSelectPlan?.map((plan) => ({
              plan: plan
            }))}
          />
          {/* <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title={<Title level={5}>Lançamento por Plano </Title>}
          >
            <Card>
              {dataSelectPlan?.map((plan, index) => (
                <Card.Grid
                  key={index}
                  onClick={() => handleClickBachEntryInvestor(plan)}
                  style={gridStyle}
                >
                  {plan.plan}
                </Card.Grid>
              ))}
            </Card>
          </Card> */}
        </Col>
      </Row>
      <Divider />
      {/* <Row>
        <Col span={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title={<Title level={5}>Lançamento para G.N.</Title>}
          >
            <Card>
              {dataSelectPlan?.map((plan, index) => (
                <Card.Grid
                  key={index}
                  onClick={() => handleClickBachEntryManager(plan)}
                  style={gridStyle}
                >
                  {plan.plan}
                </Card.Grid>
              ))}
            </Card>
          </Card>
        </Col>
      </Row> */}

      {/*  */}
      <MyModal
        isVisible={isModalInvestorVisible}
        onCancel={handleCancelInvestor}
        title={planData?.plan}
        destroyOnClose={true}
        footer={false}
      >
        <Row gutter={[8, 16]} justify="center">
          <Form
            {...layout}
            form={form}
            name="create-user"
            onFinish={onFinish}
            fields={[
              {
                name: ["plan_id"],
                value: idPlan
              },
              {
                name: ["type"],
                value: 1
              }
            ]}
          >
            <Form.Item name="percentage">
              <Input prefix="%" />
            </Form.Item>
            <Form.Item name="plan_id" hidden>
              <Input />
            </Form.Item>
            <Form.Item name="type" hidden>
              <Input />
            </Form.Item>
            <Form.Item name="date" rules={[{ required: true }]}>
              <DatePicker
                locale={locale}
                onChange={onChangeDatePicker}
                picker="month"
              />
            </Form.Item>
            <Form.Item {...tailLayout}>
              <Button
                style={{ width: "100%", marginRight: 0 }}
                type="primary"
                htmlType="submit"
                loading={sending}
              >
                CRIAR
              </Button>
            </Form.Item>
          </Form>
        </Row>
      </MyModal>

      <MyModal
        isVisible={isModalManagerVisible}
        onCancel={handleCancelManager}
        title={planData?.plan}
        destroyOnClose={true}
        footer={false}
      >
        <Row gutter={[8, 16]} justify="center">
          <Form
            {...layout}
            layout="vertical"
            form={form}
            name="create-user"
            onFinish={onFinishManager}
            fields={[
              {
                name: ["plan_id"],
                value: idPlan
              },
              {
                name: ["type"],
                value: 1
              }
            ]}
          >
            <Form.Item name="percent" label="Ganho Padrão">
              <Input prefix="%" />
            </Form.Item>
            <Form.Item name="extra" label="Ganho Extra">
              <Input prefix="%" />
            </Form.Item>
            <Form.Item name="plan" hidden>
              <Input />
            </Form.Item>
            <Form.Item name="type" hidden>
              <Input />
            </Form.Item>
            <Form.Item
              name="register"
              rules={[{ required: true }]}
              label="Mês base calculo"
            >
              <DatePicker
                locale={locale}
                onChange={onChangeDatePicker}
                picker="month"
              />
            </Form.Item>
            <Form.Item {...tailLayout}>
              <Button
                style={{ width: "100%", marginRight: 0 }}
                type="primary"
                htmlType="submit"
                loading={sendingManager}
              >
                CRIAR
              </Button>
            </Form.Item>
          </Form>
        </Row>
      </MyModal>
    </div>
  );
}

export default Batch;
