import {
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  Drawer,
  Flex,
  Form,
  Input,
  Row,
  Statistic,
  notification,
  Typography,
  Select,
  DatePicker,
  Table,
  Tag,
  Popconfirm
} from "antd";
import React, { useEffect, useState } from "react";
import { formatCurrency } from "../../helpers/moneyFormat";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useInvestorFetchRequest } from "../../Hooks/investor/useInvestor.query";
import ReactApexChart from "react-apexcharts";
import MyModal from "../../components/MyModal";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  useMovimentsPost,
  useTransactionPostRequest,
  useTransactionTotalWithdraw,
  useWithdrawBeyondPostRequest
} from "../../Hooks/transactions/useTransactions.query";
import { contractTime } from "../../helpers/contractTime";
import DefaultUpload from "../../components/uploads/DefaultUpload";
import { slugify } from "../../helpers/slugify";
import {
  useCloneWallet,
  useDisableWallet,
  useNewWallet
} from "../../Hooks/wallet/useWallet.query";
import { useContractsClientRequest } from "../../Hooks/contracts/useContracts.query";
import { usePlansFetchRequest } from "../../Hooks/plans/usePlans.query";
import { EditOutlined } from "@ant-design/icons";
import CurrencyInput from "react-currency-input";
import { IoIosList, IoIosListBox, IoIosRocket } from "react-icons/io";

const { Title, Text } = Typography;

const Gains = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const [api, contextHolder] = notification.useNotification();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [idProfile, setIdProfile] = useState(null);
  const [aport, setAport] = useState({ title: "", show: false });
  const [entry, setEntry] = useState({ title: "", show: false });
  const [withdraw, setWithdraw] = useState({ title: "", show: false });
  const [withdrawBeyond, setWithdrawBeyond] = useState({
    title: "",
    show: false
  });
  const [withdrawTotal, setWithdrawTotal] = useState({
    title: "",
    show: false
  });
  const [reinvestment, setReinvestment] = useState({ title: "", show: false });
  const [callbackResponse, setCallbackResponse] = useState("");
  const [drawerCloneWallet, setDrawerCloneWallet] = useState(false);
  const [drawerNewWallet, setDrawerNewWallet] = useState(false);
  const [drawerWalletMovimentation, setDrawerWalletMovimentation] =
    useState(false);
  const [initialDateContract, setInitialDateContract] = useState("");
  const [finalDateContract, setFinalDateContract] = useState("");
  const [planMonth, setPlanMonth] = useState(0);
  const [dataWalletMovimentation, setDataWalletMovimentation] = useState({});
  const [dateMovement, setDateMovement] = useState("");
  const [amountAfterWithdrawBeyond, setAmountAfterWithdrawBeyond] = useState({
    aport: 0,
    operation: 0,
    withdraw: 0
  });
  const [
    openActionButtonMovimentationWallet,
    setOpenActionButtonMovimentationWallet
  ] = useState(false);
  const [subDrawerActionWallet, setSubDrawerActionWallet] = useState({
    isVisible: false,
    idProfile: null,
    walletInformation: null,
    titleDrawer: "",
    actionType: ""
  });

  const { data: dataContracts } = useContractsClientRequest(id);
  const { data: dataPlans } = usePlansFetchRequest();
  const plans = dataPlans?.data?.data;

  const { data: dataInvestor, isLoading } = useInvestorFetchRequest(id);
  const investor = dataInvestor?.data?.data;

  const arrayEntrada22 = investor?.transactions?.map((transaction) => ({
    amount: transaction?.amount_money,
    month: transaction?.month_year,
    type: transaction?.type_transaction,
    percent: transaction?.percent
  }));
  const dadosAgrupados222 = arrayEntrada22?.reduce(
    (agrupados, dado) => {
      const { amount, month, type, percent } = dado;
      const valor = parseFloat(amount);

      if (month && !isNaN(valor)) {
        if (type === 1) {
          if (agrupados.percent[month]) {
            agrupados.percent[month] += percent;
          } else {
            agrupados.percent[month] = percent;
          }
        } else {
          if (agrupados.ganhos[month]) {
            agrupados.ganhos[month] += valor;
          } else {
            agrupados.ganhos[month] = valor;
          }
        }
      }

      return agrupados;
    },
    { percent: {}, ganhos: {} }
  );
  const arrayResultadoGanhos = Object.keys(dadosAgrupados222?.ganhos || {}).map(
    (data) => ({
      amount: dadosAgrupados222.ganhos[data],
      mes: data
    })
  );
  const arrayResultadoPercent = Object.keys(
    dadosAgrupados222?.percent || {}
  ).map((data) => ({
    amount: dadosAgrupados222.percent[data],
    mes: data
  }));

  const narrXPercent = arrayResultadoPercent?.map((a) => a.mes) || [];
  const narrDataGanhos = arrayResultadoGanhos?.map((a) => a.amount) || [];
  const narrDataPercent = arrayResultadoPercent?.map((a) => a.amount) || [];
  const narrXGanhos = arrayResultadoGanhos?.map((a) => a.mes) || [];
  const chart = {
    options: {
      chart: {
        type: "bar",
        width: "100%",
        height: "auto",
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          borderRadius: 2
          // dataLabels: {
          //   position: "top"
          // }
        }
      },
      dataLabels: {
        enabled: false,
        formatter: function (val) {
          return formatCurrency(val);
        },
        offsetY: 0,
        style: {
          fontSize: "12px",
          colors: ["#eee"]
        }
      },
      stroke: {
        show: true,
        width: 1,
        colors: ["#1890aa"]
      },
      grid: {
        show: true,
        borderColor: "#ccc",
        strokeDashArray: 2
      },
      labels: {
        show: true,
        align: "right",
        minWidth: 0,
        maxWidth: 160,
        style: {
          colors: ["#900"]
        }
      },
      xaxis: {
        categories: narrXGanhos
      },
      yaxis: {
        labels: {
          show: true,
          align: "right",
          minWidth: 0,
          maxWidth: 160,
          style: {
            colors: ["#000"]
          },
          formatter: function (val) {
            return formatCurrency(val);
          }
        }
      },
      title: {
        text: "Ganho Mensal",
        floating: true,
        offsetY: 330,
        align: "center",
        style: {
          color: "#444"
        }
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return formatCurrency(val);
          }
        }
      }
    },

    series: [
      {
        name: "Valor fechamento Mensal",
        data: narrDataGanhos,
        color: "#1890ff"
      }
    ]
  };
  const chart2 = {
    options: {
      chart: {
        type: "bar",
        width: "100%",
        height: "auto",
        toolbar: {
          show: true
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
          dataLabels: {
            position: "top"
          }
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return `${val} %`;
        },
        offsetY: -20,
        style: {
          fontSize: "10px",
          colors: ["#555"],
          transform: "rotate(90)"
        }
      },
      stroke: {
        show: true,
        width: 1,
        colors: ["#1890aa"]
      },
      grid: {
        show: true,
        borderColor: "#ccc",
        strokeDashArray: 2
      },
      labels: {
        show: true,
        align: "right",
        minWidth: 0,
        maxWidth: 160,
        style: {
          colors: ["#900"]
        }
      },
      xaxis: {
        categories: narrXPercent,
        position: "top",
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        crosshairs: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#D8E3F0",
              colorTo: "#BED1E6",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5
            }
          }
        },
        tooltip: {
          enabled: true
        }
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        labels: {
          show: false,
          align: "right",
          minWidth: 0,
          maxWidth: 160,
          style: {
            colors: ["#000"]
          },
          formatter: function (val) {
            return `${val} %`;
          }
        }
      },
      title: {
        text: "Porcentagem mes",
        floating: true,
        offsetY: 330,
        align: "center",
        style: {
          color: "#444"
        }
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return `${val} %`;
          }
        }
      }
    },

    series: [
      {
        name: "Porcentagem mensal",
        data: narrDataPercent,
        color: "#1890ff"
      }
    ]
  };

  const { mutateAsync, isLoading: sendingApport } = useMutation(
    useTransactionPostRequest,
    {
      onSuccess: (data) => {
        setIsModalVisible(false);
        setAport({ title: "", show: false });
        form.resetFields();
        queryClient.invalidateQueries("adminInvestor");
        if (data.data.status === 401) {
          api.error({
            message: "Error",
            description: data.data.message
          });
        }
        api.success({
          message: "Sucesso",
          description: data.data.message
        });
      }
    }
  );
  const { mutateAsync: mutateTotal, isLoading: sendingTotal } = useMutation(
    useTransactionTotalWithdraw,
    {
      onSuccess: (data) => {
        setIsModalVisible(false);
        setAport({ title: "", show: false });
        form.resetFields();
        queryClient.invalidateQueries("adminInvestor");
        if (data.data.status === 401) {
          api.error({
            message: "Error",
            description: data.data.message
          });
          return;
        }
        api.success({
          message: "Sucesso",
          description: data.data.message
        });
      }
    }
  );
  const { mutateAsync: mutateBeyond, isLoading: sendingBeyond } = useMutation(
    useWithdrawBeyondPostRequest,
    {
      onSuccess: (data) => {
        setIsModalVisible(false);
        setAport({ title: "", show: false });
        form.resetFields();
        queryClient.invalidateQueries("adminInvestor");
        if (data.data.status === 401 || data.data.status === 400) {
          api.error({
            message: "Error",
            description: data.data.message
          });
          return;
        }
        api.success({
          message: "Sucesso",
          description: data.data.message
        });
      }
    }
  );

  const onFinish = (values) => {
    mutateAsync(values);
  };
  const onFinishWithdrawTotal = (values) => {
    mutateTotal(values);
  };
  const onFinishWithdrawBeyond = (values) => {
    mutateBeyond(values);
  };

  const handleClickAport = (e) => {
    setIdProfile(e);
    setIsModalVisible(true);
    setAport({ title: "Aportar Valor", show: true });
  };
  const handleClickEntry = (e) => {
    setIdProfile(e);
    setIsModalVisible(true);
    setEntry({ title: "% de ganho mês", show: true });
  };
  const handleClickReinvestment = (e) => {
    setIdProfile(e);
    setIsModalVisible(true);
    setReinvestment({ title: "Reinvestimento", show: true });
  };
  const handleClickWithdraw = (e) => {
    setIdProfile(e);
    setIsModalVisible(true);
    setWithdraw({ title: "Sacar valores", show: true });
  };
  const handleClickWithdrawTotal = (e) => {
    setIdProfile(e);
    setIsModalVisible(true);
    setWithdrawTotal({ title: "Sacar valor total", show: true });
  };
  const handleClickWithdrawBeyond = (e) => {
    setIdProfile(e);
    setIsModalVisible(true);
    setWithdrawBeyond({ title: "Sacar valor parcial", show: true });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setAport({ title: "", show: false });
    setEntry({ title: "", show: false });
    setWithdraw({ title: "", show: false });
    setReinvestment({ title: "", show: false });
    setWithdrawBeyond({ title: "", show: false });
    setWithdrawTotal({ title: "", show: false });
  };

  const handleOpenDrawerToCloneWallet = (id) => {
    setIdProfile(id);
    setDrawerCloneWallet(true);
  };
  const handleOpenDrawerToNewWallet = (id) => {
    setIdProfile(id);
    setDrawerNewWallet(true);
  };

  const handleUploadComplete = (data) => {
    setCallbackResponse(data);
  };

  const { mutate: sendDisableWallet, isLoading: sendingDisableWallet } =
    useMutation(useDisableWallet, {
      onSuccess: (data) => {
        setDrawerCloneWallet(false);
        api.success({
          message: "Sucesso",
          description: data.data.message
        });
        // setCallbackResponse(data);
        queryClient.invalidateQueries("adminInvestor");
      },
      onError: (error) => {
        console.log("Erro ao clonar carteira:", error);
      }
    });

  const { mutate: sendCloneWallet, isLoading: sendingCloneWallet } =
    useMutation(useCloneWallet, {
      onSuccess: (data) => {
        setDrawerCloneWallet(false);
        api.success({
          message: "Sucesso",
          description: data.data.message
        });
        // setCallbackResponse(data);
        queryClient.invalidateQueries("adminInvestor");
      },
      onError: (error) => {
        console.log("Erro ao clonar carteira:", error);
      }
    });
  const { mutate: sendNewWallet, isLoading: sendingNewWallet } = useMutation(
    useNewWallet,
    {
      onSuccess: (data) => {
        setDrawerNewWallet(false);
        queryClient.invalidateQueries("adminInvestor");
      },
      onError: (error) => {
        console.log("Erro ao clonar carteira:", error);
      }
    }
  );
  const { mutateAsync: mutateMovements, isLoading: sendingMovements } =
    useMutation(useMovimentsPost, {
      onSuccess: (data) => {
        setSubDrawerActionWallet({
          isVisible: false,
          idProfile: null,
          walletInformation: null,
          titleDrawer: "",
          actionType: ""
        });
        setIsModalVisible(false);
        setAport({ title: "", show: false });
        form.resetFields();
        queryClient.invalidateQueries("contracts-client");
        queryClient.invalidateQueries("adminInvestor");
        if (data.data.status === 401) {
          api.error({
            message: "Error",
            description: data.data.message
          });
        }
        api.success({
          message: "Sucesso",
          description: data.data.message
        });
      }
    });

  const onFinishMovements = (values) => {
    const data = {
      ...values,
      date: dateMovement,
      amount: values.amount
        ? values.amount.replace("R$ ", "").replace(/,/g, "")
        : null
    };
    mutateMovements(data);
  };

  const onFinishCloneWallet = (formValues) => {
    const data = {
      id: id,
      pdf_file: callbackResponse?.imageUrl,
      ...formValues
    };
    sendCloneWallet(data);
  };

  const onFinishNewWallet = (formValues) => {
    const data = {
      ...formValues,
      investor: id,
      initial_date: initialDateContract,
      final_date: finalDateContract,
      pdf_file: callbackResponse?.imageUrl
    };

    sendNewWallet(data);
  };

  const handleChangePlan = (idPlan) => {
    const selectedPlan = plans.find((plan) => plan.id === idPlan);
    setPlanMonth(selectedPlan?.contract_time);
    form.resetFields(["final_date", "initial_date"]);
    setInitialDateContract("");
    setFinalDateContract("");
  };

  const changeDate = (date, dateString) => {
    setDateMovement(dateString);
  };
  const onChangeInitialDateContract = (date, dateString) => {
    setInitialDateContract(dateString);
    setFinalDateContract(contractTime(dateString, planMonth));
    form.setFieldsValue({
      final_date: contractTime(dateString, planMonth)
    });
  };

  const handleOpenDrawerWalletMovimentation = (data) => {
    setDrawerWalletMovimentation(true);
    setDataWalletMovimentation(data);
  };

  const handleDrawerActionButton = () => {
    setOpenActionButtonMovimentationWallet(true);
  };

  const handleActionWalletClick = (actionType, e) => {
    const actionConfig = {
      aport: "Aportar Valor",
      entry: "% de ganho mês",
      reinvestment: "Reinvestimento",
      withdraw: "Sacar valores",
      withdrawTotal: "Sacar valor total",
      withdrawBeyond: "Sacar valor parcial"
    };

    const title = actionConfig[actionType] || "Ação desconhecida";

    setSubDrawerActionWallet({
      isVisible: true,
      idProfile: e,
      walletInformation: dataWalletMovimentation,
      titleDrawer: title,
      actionType: actionType
    });
  };

  const handleClickAportDrawer = (e) => handleActionWalletClick("aport", e);
  const handleClickRentabilityDrawer = (e) =>
    handleActionWalletClick("entry", e);
  const handleClickReinvestmentDrawer = (e) =>
    handleActionWalletClick("reinvestment", e);
  const handleClickWithdrawDrawer = (e) =>
    handleActionWalletClick("withdraw", e);
  const handleClickWithdrawBeyondDrawer = (e) =>
    handleActionWalletClick("withdrawBeyond", e);
  const handleClickDisableWalletDrawer = (e) => {
    const data = {
      client_id: e.id,
      ...dataWalletMovimentation
    };
    sendDisableWallet(data);
  };

  const handleChangeAmountWithdraw = (e) => {
    let amountToWithdraw = e.replace("R$ ", "").replace(/,/g, "");

    let amountInOperation = parseFloat(
      subDrawerActionWallet?.walletInformation?.wallet?.amount
    );
    let amountAported = parseFloat(
      subDrawerActionWallet?.walletInformation?.wallet?.income
    );
    let availableWithdraw = parseFloat(
      subDrawerActionWallet?.walletInformation?.wallet?.available_for_withdrawal
    );

    let expense = parseFloat(
      subDrawerActionWallet?.walletInformation?.wallet?.expense
    );

    if (availableWithdraw > 0) {
      amountInOperation = amountInOperation + availableWithdraw;
    }

    let subtractRequestedAmountFromOperatingBalance =
      amountInOperation - amountToWithdraw;

    let compareRemainingOperatingBalanceWithContribution =
      subtractRequestedAmountFromOperatingBalance - amountAported;

    if (amountToWithdraw > amountInOperation) {
      api.error({
        message: "Error",
        description: "Valor solicitado maior que o saldo disponível"
      });
      return;
    }

    if (compareRemainingOperatingBalanceWithContribution < 0) {
      amountAported =
        compareRemainingOperatingBalanceWithContribution < 0
          ? amountAported + compareRemainingOperatingBalanceWithContribution
          : amountAported;
    }

    setAmountAfterWithdrawBeyond({
      operation: amountInOperation - amountToWithdraw,
      aport: amountAported,
      withdraw: expense + amountToWithdraw * -1
    });
  };

  return (
    <>
      {contextHolder}
      <Title level={4}>Ganhos</Title>
      <Flex gap="small" wrap="wrap" style={{ marginBottom: "10px" }}>
        {investor?.clientWallets?.length === 0 && (
          <Button onClick={() => handleOpenDrawerToCloneWallet(investor.id)}>
            Clonar e atualizar Wallet
          </Button>
        )}

        <Button onClick={() => handleOpenDrawerToNewWallet(investor.id)}>
          Criar nova carteira
        </Button>
      </Flex>
      {investor?.clientWallets?.length !== 0 ?? (
        <Flex gap="small" wrap="wrap">
          <Button onClick={() => handleClickAport(investor.user_id)}>
            Aporte
          </Button>
          <Button onClick={() => handleClickEntry(investor.user_id)}>
            Rentabilidade
          </Button>
          <Button onClick={() => handleClickReinvestment(investor.user_id)}>
            Reinvestimento
          </Button>
          <Button onClick={() => handleClickWithdraw(investor.user_id)}>
            Sacar
          </Button>
          <Button onClick={() => handleClickWithdrawTotal(investor.user_id)}>
            Saque valor total
          </Button>
          <Button onClick={() => handleClickWithdrawBeyond(investor.user_id)}>
            Saque acima rentabilidade
          </Button>
        </Flex>
      )}
      <Divider />

      {investor?.clientWallets?.length === 0 ? (
        <>
          <Row gutter={10}>
            <Col md={12} sm={24}>
              <Card bordered={true}>
                <Statistic
                  title="Valor na carteira"
                  value={formatCurrency(
                    investor?.wallet?.amount,
                    investor?.currency
                  )}
                  valueStyle={{
                    color: "#3f8600"
                  }}
                />
              </Card>
            </Col>
            <Col md={12} sm={24}>
              <Card bordered={true}>
                <Statistic
                  title="Valor ja sacado"
                  value={formatCurrency(
                    investor?.wallet?.expense,
                    investor?.currency
                  )}
                  valueStyle={{
                    color: "#cf1322"
                  }}
                />
              </Card>
            </Col>
          </Row>
          <br />
          <Row gutter={[10]}>
            <Col span={24} md={12} className="mb-24 ">
              <Card bordered={false} className="criclebox h-full">
                <ReactApexChart
                  className=""
                  options={chart.options}
                  series={chart.series}
                  type="bar"
                  height={220}
                />
              </Card>
            </Col>
            <Col span={24} md={12} className="mb-24 ">
              <Card bordered={false} className="criclebox h-full">
                <ReactApexChart
                  className=""
                  options={chart2.options}
                  series={chart2.series}
                  type="bar"
                  height={220}
                />
              </Card>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Row gutter={10}>
            <Col md={24} className="mb-24">
              <Table
                size="small"
                tableLayout="auto"
                dataSource={investor?.clientWallets?.map((wallet) => ({
                  ...wallet,
                  key: wallet.id,
                  ac: wallet.id,
                  name: wallet.planWallet?.plan,
                  amount: formatCurrency(
                    wallet.wallet.amount,
                    investor?.currency
                  ),
                  income: formatCurrency(
                    wallet.wallet.income,
                    investor?.currency
                  ),
                  expense: formatCurrency(
                    wallet.wallet.expense,
                    investor?.currency
                  ),
                  available_for_withdrawal: formatCurrency(
                    wallet.wallet.available_for_withdrawal,
                    investor?.currency
                  ),
                  percentage: wallet.planWallet,
                  currency: investor?.currency,
                  isDisabled: wallet.wallet.deleted_at
                }))}
                columns={[
                  // {
                  //   title: "",
                  //   dataIndex: "isDisabled",
                  //   key: "isDisabled",
                  //   render: (text) => {
                  //     return text && <Tag color="error">Desabilitado</Tag>;
                  //   },
                  //   width: 10
                  // },
                  {
                    title: "",
                    dataIndex: "ac",
                    key: "ac",
                    render: (text, record) => {
                      return (
                        <>
                          <Flex wrap="wrap">
                            {record.isDisabled && (
                              <Tag color="error">Desabilitado</Tag>
                            )}
                            <div>
                              <Button
                                type="text"
                                onClick={() =>
                                  handleOpenDrawerWalletMovimentation(record)
                                }
                              >
                                <IoIosRocket size={20} />
                              </Button>
                              <Link to={`${record.wallet_id}`}>
                                <Button type="text">
                                  <IoIosListBox size={20} />
                                </Button>
                              </Link>
                            </div>
                          </Flex>
                        </>
                      );
                    },
                    width: "5%"
                  },
                  {
                    title: "Plano contratado",
                    dataIndex: "name",
                    key: "name",
                    render: (text) => {
                      return (
                        <>
                          <p>{text} </p>
                        </>
                      );
                    }
                  },
                  {
                    title: "Valor em operação",
                    dataIndex: "amount",
                    key: "amount",
                    render: (text) => <p>{text}</p>
                  },
                  {
                    title: "Valor de aporte",
                    dataIndex: "income",
                    key: "income",
                    render: (text) => <p>{text}</p>
                  },
                  {
                    title: "Disponível para saque",
                    dataIndex: "available_for_withdrawal",
                    key: "available_for_withdrawal",
                    render: (text) => <p>{text}</p>
                  }
                ]}
              />
            </Col>
          </Row>
        </>
      )}

      <Divider />

      <Drawer
        destroyOnClose={true}
        title="Criar nova Carteira"
        placement="right"
        width={800}
        onClose={() => {
          setDrawerNewWallet(false);
          setPlanMonth(0);
          setInitialDateContract("");
          setFinalDateContract("");
          form.resetFields();
        }}
        open={drawerNewWallet}
      >
        <Form
          style={{ paddingLeft: "20px", paddingRight: "20px", marginTop: 20 }}
          form={form}
          layout="vertical"
          onFinish={onFinishNewWallet}
        >
          <Flex justify="space-between" align="">
            <div>
              <DefaultUpload
                title={`CONTRATO_PRIVATE_EQUITY-${slugify(investor.name)}-${
                  investor.start_of_contract
                }`}
                label={`Enviar contrato`}
                folder={`contract/${slugify(investor.name)}/${
                  investor?.myPlan?.plan
                }`}
                controller="contract"
                action="uploadcontract"
                callback={handleUploadComplete}
              />
            </div>
            <Divider type="vertical" style={{ height: 45 }} />
            Ou
            <Divider type="vertical" style={{ height: 45 }} />
            {/* BUSCA CONTRATOS */}
            <Form.Item name="contract_number" style={{ width: "70%" }}>
              <Select
                style={{ width: "100%" }}
                options={dataContracts?.map((item) => ({
                  value: item.contract_number,
                  label: item.contract_number
                }))}
              />
            </Form.Item>
          </Flex>

          {/* BUSCA PLANOS */}
          <Form.Item name="plan_id" required>
            <Select
              style={{ width: "100%" }}
              onChange={handleChangePlan}
              options={plans?.map((item) => ({
                value: item.id,
                label: (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span style={{ flex: 1 }}>{item.plan}</span>
                    <span style={{ flex: 1 }}>{item.contract_time} Meses</span>
                  </div>
                )
              }))}
            />
          </Form.Item>

          <Form.Item
            name="initial_date"
            label="Inicio do contrato"
            rules={[{ required: true }]}
          >
            <DatePicker
              onChange={onChangeInitialDateContract}
              format={"DD/MM/YYYY"}
            />
          </Form.Item>

          <Form.Item
            name="final_date"
            label="Fim do contraro"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>

          <Form.Item>
            <Button
              // disabled={!callbackResponse}
              loading={!!sendingNewWallet}
              style={{ width: "100%", marginRight: 0 }}
              type="primary"
              htmlType="submit"
            >
              CRIAR
            </Button>
          </Form.Item>
        </Form>

        <Descriptions
          title="DADOS"
          bordered
          column={1}
          items={[
            {
              key: "1",
              label: "Contrato",
              children:
                form.getFieldValue("contract_number") ||
                callbackResponse?.imageUrl
            },
            {
              key: "2",
              label: "Plano",
              children: `${planMonth} Meses`
            },
            {
              key: "3",
              label: "Inicio do contrato",
              children: initialDateContract
            },
            {
              key: "4",
              label: "Fim do contrato",
              children: finalDateContract
            }
          ]}
        />
      </Drawer>

      <Drawer
        destroyOnClose={true}
        title="Clonar Carteira"
        placement="right"
        width={500}
        onClose={() => setDrawerCloneWallet(false)}
        open={drawerCloneWallet}
      >
        <Descriptions
          column={1}
          bordered
          items={[
            {
              key: "1",
              label: "Plano",
              children: investor?.myPlan?.plan
            },
            {
              key: "2",
              label: "Tempo de contrato",
              children: `${investor?.myPlan?.contract_time} meses`
            },
            {
              key: "3",
              label: "Faixa de ganho",
              children: `${investor?.myPlan?.gains_between?.split("-")[0]}% - ${
                investor?.myPlan?.gains_between?.split("-")[1]
              }%`
            }
          ]}
          labelStyle={{ width: 150 }}
        />

        <Divider />
        <DefaultUpload
          title={`CONTRATO_PRIVATE_EQUITY-${slugify(investor?.name)}-${
            investor?.start_of_contract
          }`}
          label={`Enviar contrato existente`}
          folder={`contract/${slugify(investor?.name)}/${
            investor?.myPlan?.plan
          }`}
          controller="contract"
          action="uploadcontract"
          callback={handleUploadComplete}
        />
        <Divider />

        <p>
          Link arquivo hospedado: <Text code>{callbackResponse?.imageUrl}</Text>{" "}
        </p>

        <Form
          style={{ paddingLeft: "20px", paddingRight: "20px", marginTop: 20 }}
          form={form}
          layout="vertical"
          onFinish={onFinishCloneWallet}
          initialValues={{
            initial_date: investor?.start_of_contract || 0,
            final_date: contractTime(
              investor?.start_of_contract,
              investor?.myPlan?.contract_time
            )
          }}
        >
          <Form.Item
            name="initial_date"
            label="Inicio do contrato"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="final_date"
            label="Fim do contraro"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button
              disabled={!callbackResponse}
              loading={!!sendingCloneWallet}
              style={{ width: "100%", marginRight: 0 }}
              type="primary"
              htmlType="submit"
            >
              CRIAR
            </Button>
          </Form.Item>
        </Form>
      </Drawer>

      <Drawer
        size="large"
        width={620}
        destroyOnClose={true}
        title={`Carteira - plano ${dataWalletMovimentation?.planWallet?.plan} (${dataWalletMovimentation?.contract_id})`}
        placement="right"
        onClose={() => setDrawerWalletMovimentation(false)}
        open={drawerWalletMovimentation}
      >
        <Flex gap={5} style={{ marginBottom: 10 }} justify="end">
          <Popconfirm
            title="Desabilitar carteira"
            description="Voce tem certeza q deseja desabilitar essa carteira?"
            onConfirm={() => handleClickDisableWalletDrawer(investor)}
            // onCancel={cancel}
            okText="Sim"
            cancelText="Não"
          >
            <Button type="dashed" danger>
              Desabilitar carteira
            </Button>
          </Popconfirm>
        </Flex>
        <Flex gap={5} style={{ marginBottom: 10 }}>
          <Button block onClick={() => handleClickAportDrawer(investor)}>
            Novo aporte
          </Button>
          <Button block onClick={() => handleClickRentabilityDrawer(investor)}>
            Lançar rentabilidade
          </Button>
          <Button block onClick={() => handleClickReinvestmentDrawer(investor)}>
            Reinvestir valor
          </Button>
          <Button block onClick={() => handleClickWithdrawDrawer(investor)}>
            Sacar
          </Button>
          <Button
            block
            type="dashed"
            onClick={() => handleClickWithdrawBeyondDrawer(investor)}
          >
            Saque extra
          </Button>
        </Flex>

        <Descriptions
          column={1}
          bordered
          items={[
            {
              key: "6",
              label: "Validade do contrato",
              children: `de ${dataWalletMovimentation.initial_date} até ${dataWalletMovimentation.final_date}`
            },
            {
              key: "1",
              label: "Plano",
              children: dataWalletMovimentation?.planWallet?.plan
            },
            {
              key: "2",
              label: "Numero de conta",
              children: dataWalletMovimentation.wallet?.account_number
            },
            {
              key: "3",
              label: "Valor em operação",
              children: formatCurrency(
                dataWalletMovimentation.wallet?.amount,
                dataWalletMovimentation.currency
              )
            },
            {
              key: "4",
              label: "Valor de aporte",
              children: formatCurrency(
                dataWalletMovimentation.wallet?.income,
                dataWalletMovimentation.currency
              )
            },
            {
              key: "5",
              label: "Disponivel para saque",
              children: formatCurrency(
                dataWalletMovimentation.wallet?.available_for_withdrawal,
                dataWalletMovimentation.currency
              )
            }
          ]}
          labelStyle={{ width: 150 }}
        />

        <Drawer
          title={subDrawerActionWallet.titleDrawer}
          headerStyle={{ textAlign: "center" }}
          width={520}
          closable={false}
          onClose={() => setSubDrawerActionWallet({ isVisible: false })}
          open={subDrawerActionWallet.isVisible}
        >
          <Form
            preserve={false}
            name="basic"
            onFinish={onFinishMovements}
            autoComplete="off"
            fields={[
              {
                name: ["investor"],
                value: subDrawerActionWallet.idProfile?.user_id
              },
              {
                name: ["wallet_id"],
                value: subDrawerActionWallet?.walletInformation?.wallet_id
              },
              {
                name: ["account_number"],
                value:
                  subDrawerActionWallet?.walletInformation?.wallet
                    ?.account_number
              }
            ]}
            form={form}
          >
            <Form.Item name="investor" hidden>
              <Input disabled />
            </Form.Item>
            <Form.Item name="wallet_id" hidden>
              <Input disabled />
            </Form.Item>
            <Form.Item name="account_number" hidden>
              <Input disabled />
            </Form.Item>
            {subDrawerActionWallet.actionType === "aport" && (
              <Col>
                <Row wrap={false}>
                  <Col flex="auto">
                    <Form.Item name="income">
                      <CurrencyInput
                        prefix="R$ "
                        style={{
                          boxSizing: "border-box",
                          margin: 0,
                          padding: "13px 11px",
                          color: "rgba(0, 0, 0, 0.88)",
                          fontSize: "14px",
                          lineHeight: 1.5714285714285714,
                          listStyle: "none",
                          position: "relative",
                          display: "inline-block",
                          width: "100%",
                          minWidth: 0,
                          borderRadius: " 6px",
                          transition: " all 0.2s",
                          background: "#ffffff",
                          borderWidth: "1px",
                          borderStyle: "solid",
                          borderColor: "#d9d9d9"
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row wrap={false}>
                  <Col flex="auto">
                    <Form.Item name="date">
                      <DatePicker format={"DD-MM-YYYY"} onChange={changeDate} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row wrap={false}>
                  <Col flex="auto">
                    <Form.Item name="type_transaction" initialValue="2" hidden>
                      <Input prefix="" />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            )}
            {subDrawerActionWallet.actionType === "entry" && (
              <>
                <Row wrap={false}>
                  <Col flex="auto">
                    <Form.Item name="percent">
                      <Input suffix="%" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row wrap={false}>
                  <Col flex="auto">
                    <Form.Item name="date">
                      <DatePicker
                        format={"DD-MM-YYYY"}
                        onChange={changeDate}
                        picker="month"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row wrap={false}>
                  <Col flex="auto">
                    <Form.Item name="type_transaction" initialValue="1" hidden>
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
            {subDrawerActionWallet.actionType === "reinvestment" && (
              <>
                <Row wrap={false} gutter={[16, 16]}>
                  <Col flex="auto">
                    <Descriptions
                      title=""
                      layout="vertical"
                      bordered
                      style={{ marginBottom: 20 }}
                    >
                      <Descriptions.Item label="Valor a ser reinvestido">
                        {formatCurrency(
                          subDrawerActionWallet?.walletInformation?.wallet
                            ?.available_for_withdrawal,
                          subDrawerActionWallet?.walletInformation?.currency
                        )}
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                </Row>
                <Row wrap={false}></Row>
                <Row wrap={false}>
                  <Col flex="auto">
                    <Form.Item name="date">
                      <DatePicker format={"DD-MM-YYYY"} onChange={changeDate} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row wrap={false}>
                  <Col flex="auto">
                    <Form.Item name="type_transaction" initialValue="8" hidden>
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
            {subDrawerActionWallet.actionType === "withdraw" && (
              <>
                <Row wrap={false} gutter={[16, 16]}>
                  <Col flex="auto">
                    <Descriptions
                      title=""
                      layout="vertical"
                      bordered
                      style={{ marginBottom: 20 }}
                    >
                      <Descriptions.Item label="Valor disponivel para saque">
                        {formatCurrency(
                          subDrawerActionWallet?.walletInformation?.wallet
                            ?.available_for_withdrawal,
                          subDrawerActionWallet?.walletInformation?.currency
                        )}
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                </Row>
                <Row wrap={false}>
                  <Col flex="auto">
                    <Form.Item name="date">
                      <DatePicker format={"DD-MM-YYYY"} onChange={changeDate} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row wrap={false}>
                  <Col flex="auto">
                    <Form.Item
                      name="amount"
                      initialValue={
                        subDrawerActionWallet?.walletInformation?.wallet
                          ?.available_for_withdrawal
                      }
                    >
                      <CurrencyInput
                        prefix="R$ "
                        style={{
                          boxSizing: "border-box",
                          margin: 0,
                          padding: "13px 11px",
                          color: "rgba(0, 0, 0, 0.88)",
                          fontSize: "14px",
                          lineHeight: 1.5714285714285714,
                          listStyle: "none",
                          position: "relative",
                          display: "inline-block",
                          width: "100%",
                          minWidth: 0,
                          borderRadius: " 6px",
                          transition: " all 0.2s",
                          background: "#ffffff",
                          borderWidth: "1px",
                          borderStyle: "solid",
                          borderColor: "#d9d9d9"
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row wrap={false}>
                  <Col flex="auto">
                    <Form.Item name="type_transaction" initialValue="5" hidden>
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
            {subDrawerActionWallet.actionType === "withdrawBeyond" && (
              <>
                <Row wrap={false} gutter={[16, 16]}>
                  <Col flex="auto">
                    <Descriptions
                      title="Valores atuais"
                      layout="vertical"
                      bordered
                      style={{ marginBottom: 20 }}
                    >
                      <Descriptions.Item label="Valor de aporte">
                        {formatCurrency(
                          subDrawerActionWallet?.walletInformation?.wallet
                            ?.income,
                          subDrawerActionWallet?.walletInformation?.currency
                        )}
                      </Descriptions.Item>
                      <Descriptions.Item label="Valor em operação">
                        {formatCurrency(
                          subDrawerActionWallet?.walletInformation?.wallet
                            ?.amount,
                          subDrawerActionWallet?.walletInformation?.currency
                        )}
                      </Descriptions.Item>
                    </Descriptions>
                    <Descriptions
                      title="Valores apos saque"
                      layout="vertical"
                      bordered
                      style={{ marginBottom: 20 }}
                    >
                      <Descriptions.Item label="Valor de aporte">
                        {formatCurrency(
                          amountAfterWithdrawBeyond.aport,
                          subDrawerActionWallet?.walletInformation?.currency
                        )}
                      </Descriptions.Item>
                      <Descriptions.Item label="Valor em operação">
                        {formatCurrency(
                          amountAfterWithdrawBeyond.operation,
                          subDrawerActionWallet?.walletInformation?.currency
                        )}
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                </Row>
                <Divider />
                <Row wrap={false}>
                  <Col flex="auto">
                    <Form.Item name="date">
                      <DatePicker format={"DD-MM-YYYY"} onChange={changeDate} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row wrap={false}>
                  <Col flex="auto">
                    <Form.Item
                      name="amount"
                      initialValue={
                        subDrawerActionWallet?.walletInformation?.wallet
                          ?.available_for_withdrawal
                      }
                    >
                      <CurrencyInput
                        prefix="R$ "
                        style={{
                          boxSizing: "border-box",
                          margin: 0,
                          padding: "13px 11px",
                          color: "rgba(0, 0, 0, 0.88)",
                          fontSize: "14px",
                          lineHeight: 1.5714285714285714,
                          listStyle: "none",
                          position: "relative",
                          display: "inline-block",
                          width: "100%",
                          minWidth: 0,
                          borderRadius: " 6px",
                          transition: " all 0.2s",
                          background: "#ffffff",
                          borderWidth: "1px",
                          borderStyle: "solid",
                          borderColor: "#d9d9d9"
                        }}
                        onChange={handleChangeAmountWithdraw}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row wrap={false}>
                  <Col flex="auto">
                    <Form.Item name="type_transaction" initialValue="3" hidden>
                      <Input />
                    </Form.Item>
                    <Form.Item name="origin" initialValue="operation" hidden>
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}

            {/* SEND BUTTON */}
            <Col flex="auto">
              <Form.Item>
                <Button
                  loading={sendingMovements}
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                >
                  Enviar
                </Button>
              </Form.Item>
            </Col>
          </Form>
        </Drawer>
      </Drawer>

      <MyModal
        isVisible={isModalVisible}
        onCancel={handleCancel}
        onOk={onFinish}
        title={
          <h2 style={{ textAlign: "center" }}>
            {aport?.title ||
              entry?.title ||
              withdraw?.title ||
              reinvestment?.title}
          </h2>
        }
        okButtonProps={{
          hidden: true
        }}
        cancelButtonProps={{
          disabled: true
        }}
      >
        <Form
          preserve={false}
          name="basic"
          onFinish={onFinish}
          autoComplete="off"
          fields={[
            {
              name: ["investor"],
              value: investor?.user_id
            }
          ]}
          form={form}
        >
          {aport?.show && (
            <Col>
              <Row wrap={false}>
                <Col flex="auto">
                  <Form.Item name="amount">
                    <Input prefix={investor?.currency} />
                  </Form.Item>
                </Col>
              </Row>
              <Row wrap={false}>
                <Col flex="auto">
                  <Form.Item name="investor" hidden>
                    <Input prefix="" />
                  </Form.Item>
                  <Form.Item name="type" initialValue="2" hidden>
                    <Input prefix="" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          )}

          {entry?.show && (
            <Col>
              <Row wrap={false}>
                <Col flex="auto">
                  <Form.Item name="percentage">
                    <Input prefix="%" />
                  </Form.Item>
                </Col>
              </Row>
              <Row wrap={false} hidden>
                <Col flex="auto">
                  <Form.Item name="investor" hidden>
                    <Input prefix="" />
                  </Form.Item>
                  <Form.Item name="type" initialValue="1" hidden>
                    <Input prefix="" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          )}

          {withdraw?.show && (
            <Col>
              <Row wrap={false} gutter={[16, 16]}>
                <Col flex="auto">
                  <Descriptions title="" layout="vertical" bordered>
                    <Descriptions.Item label="Valor disponível para saque">
                      {formatCurrency(
                        investor?.wallet?.available_for_withdrawal,
                        investor?.currency
                      )}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              </Row>
              <br />
              <Row wrap={false}>
                <Col flex="auto">
                  <Form.Item
                    name="amount"
                    initialValue={investor?.wallet?.available_for_withdrawal}
                  >
                    <Input prefix={investor?.currency} />
                  </Form.Item>
                </Col>
              </Row>
              <Row wrap={false} hidden>
                <Col flex="auto">
                  <Form.Item name="investor" hidden>
                    <Input prefix="" />
                  </Form.Item>
                  <Form.Item name="type" initialValue="5" hidden>
                    <Input prefix="" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          )}

          {reinvestment?.show && (
            <>
              <Col>
                <Row wrap={false} gutter={[16, 16]}>
                  <Col flex="auto">
                    <Descriptions title="" layout="vertical" bordered>
                      <Descriptions.Item label="Valor a ser reinvestido">
                        {formatCurrency(
                          investor?.wallet?.available_for_withdrawal,
                          investor?.currency
                        )}
                        <Form.Item
                          name="amount"
                          initialValue={
                            investor?.wallet?.available_for_withdrawal
                          }
                          hidden
                        >
                          <Input prefix="" />
                        </Form.Item>
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                </Row>
                <Row wrap={false} hidden>
                  <Col flex="auto">
                    <Form.Item name="investor" hidden>
                      <Input prefix="" />
                    </Form.Item>
                    <Form.Item name="type" initialValue="8" hidden>
                      <Input prefix="" />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <br />
            </>
          )}

          {withdrawTotal?.show || withdrawBeyond?.show ? (
            ""
          ) : (
            <Col flex="auto">
              <Form.Item>
                <Button
                  loading={sendingApport}
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                >
                  Salvar
                </Button>
              </Form.Item>
            </Col>
          )}
        </Form>

        {withdrawTotal?.show && (
          <Form
            preserve={false}
            name="basic"
            onFinish={onFinishWithdrawTotal}
            autoComplete="off"
            fields={[
              {
                name: ["investor"],
                value: investor?.user_id
              }
            ]}
            form={form}
          >
            <Col style={{ marginBottom: 16 }}>
              <Row wrap={false}>
                <Col flex="auto">
                  <Descriptions title="" layout="vertical" bordered>
                    <Descriptions.Item label="Valor total a ser retirado">
                      {formatCurrency(
                        investor?.wallet?.amount,
                        investor?.currency
                      )}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              </Row>
              <Row wrap={false} hidden>
                <Col flex="auto">
                  <Form.Item name="investor" hidden>
                    <Input prefix="" />
                  </Form.Item>
                  <Form.Item name="type" initialValue="99" hidden>
                    <Input prefix="" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col flex="auto">
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                >
                  Sacar
                </Button>
              </Form.Item>
            </Col>
          </Form>
        )}
        {withdrawBeyond?.show && (
          <Form
            preserve={false}
            name="basic"
            onFinish={onFinishWithdrawBeyond}
            autoComplete="off"
            fields={[
              {
                name: ["investor"],
                value: investor?.user_id
              }
            ]}
            form={form}
          >
            <Col style={{ marginBottom: 16 }}>
              <Row wrap={false}>
                <Col flex="auto">
                  <Descriptions title="" layout="vertical" bordered>
                    <Descriptions.Item label="Valor disponível">
                      {formatCurrency(
                        investor?.wallet?.amount,
                        investor?.currency
                      )}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              </Row>
              <Row wrap={false}>
                <Col flex="auto">
                  <Form.Item name="amount">
                    <Input prefix={investor?.currency} />
                  </Form.Item>
                </Col>
              </Row>
              <Row wrap={false}>
                <Col flex="auto">
                  <Form.Item name="investor" hidden>
                    <Input prefix="" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col flex="auto">
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                >
                  Sacar
                </Button>
              </Form.Item>
            </Col>
          </Form>
        )}
      </MyModal>
    </>
  );
};

export default Gains;
